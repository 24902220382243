/*--------------------------------------------------------------------/
	drawer
/--------------------------------------------------------------------*/

$spnav_menu_w: 260px; // スライドメニューの横幅
$spnav_link_p: 10%; // スライドメニューのボタンの左右の余白
$spnav_btn_w_sp: 58px; // ハンバーガーメニューの縦横幅 SP用
$spnav_btn_w_pc: 72px; // ハンバーガーメニューの縦横幅 PC用
$spnav_font_s: 15px; // メニューのフォントサイズ

.header.fixed { //スティッキーヘッダー調整
  min-height: $spnav_btn_w_sp;
  @include media-breakpoint-up(lg) {
    min-height: $spnav_btn_w_pc;
  }
}

// wrapperにハンバーガーボタンを押した際の設定
#wrapper,
.header {
  @include media-breakpoint-down(lg) {
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &.active {
    //activeのクラス名がついたら表示
    transform: translateX(-#{$spnav_menu_w});
  }
}

/* toggle button */
.sp-nav__btn {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

#sp-nav-toggle {
  display: none;
  &:checked + .sp-nav-open {
    transform: translateX(-#{$spnav_menu_w});
    & span,
    &::before,
    &::after {
      border-color: $white;
    }
    & span {
      transform: scaleX(0);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &:checked ~ .sp-nav-close {
    pointer-events: auto;
    background: rgba($black, 0.2);
  }
  &:checked ~ .sp-nav {
    transform: none;
  }
}

.sp-nav-open,
.sp-nav-close {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
}
.sp-nav-open {
  width: $spnav_btn_w_sp;
  height: $spnav_btn_w_sp;
  z-index: z-index(hamburger);
  transition: 0.6s, transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  @include media-breakpoint-up(lg) {
    width: $spnav_btn_w_pc;
    height: $spnav_btn_w_pc;
  }
  & span,
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 30%;
    width: 40%;
    border-bottom: 1px solid;
    border-color: $black;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &::before {
    transform: translateY(-8px);
    @include media-breakpoint-up(lg) {
      transform: translateY(-10px);
    }
  }
  &::after {
    transform: translateY(8px);
    @include media-breakpoint-up(lg) {
      transform: translateY(10px);
    }
  }
  .sp-nav__btn.c-header & span,
  .sp-nav__btn.c-header &::before,
  .sp-nav__btn.c-header &::after {
    border-color: $white;
  }
  .sp-nav__btn.fixed & span,
  .sp-nav__btn.fixed &::before,
  .sp-nav__btn.fixed &::after {
    border-color: $black;
  }
}

.sp-nav-close {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: 0.6s;
  z-index: z-index(sp-nav);
}

/* drawer menu */
.sp-nav {
  z-index: z-index(hamburger);
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: $spnav_menu_w;
  height: 100%;
  background: $b-gray;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  & h2 {
    color: $white;
    text-align: center;
  }
  &__inner {
    overflow-y: auto;
  }
}
.sp-nav__logo {
  width: 80%;
  @include m-a;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0 1.5em;
  a {
    padding: 0.7rem $spnav_link_p;
    display: block;
  }
}

.sp-nav__list {
  @include line-h(1.5);
  padding: 0 $spnav_link_p;
  li {
    &:first-child {
      border-top: 1px solid $d-gray;
    }
    border-bottom: 1px solid $d-gray;
    a {
      color: $white;
      display: block;
      padding: 1em 0;
      text-decoration: inherit;
      transition: background 0.6s;
      font-size: $spnav_font_s;
      @include f-size(15);
      @include transition;
      &:hover {
        padding-left: 0.5em;
        color: $gray;
      }
    }
  }
}

// 電話番号
.sp-nav__tel {
  color: $white;
  padding: 2rem $spnav_link_p 2em;
  p {
    font-size: 13px;
  }
}
.sp-nav__num {
  @include f-family(font02);
  font-size: 18px;
  .tel-link {
    font-size: 24px;
    padding-left: 0.2em;
  }
  a {
    color: $white;
  }
}
