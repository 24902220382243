/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */

/* main */
.main {
	@include clearfix;
	img {
		display: block;
	}
}
/* contents */
.contents {
	background: $main_bg;
}

.l-cont_lg {
	max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}
.l-cont {
	max-width: map-get($container-max-widths, xl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}
.l-cont_xxl {
	max-width: map-get($container-max-widths, xxl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xxl) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
	}
}

.l-cont_full {
	max-width: 100%; //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
	}
}

/* contents-box */
// Layout box
.l-box {
	width: 100%;
	z-index: 1;
	position: relative;
	&:not(:last-of-type) {
		margin-bottom: 8rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10rem;
		}
	}
}

[class*="bg-box"] {
	padding: 2rem 4%;
	@include media-breakpoint-up(sm) {
		padding: 2rem 6%;
	}
}
.bg-box {
	&--gray {
		background: $p-gray;
	}
}

/* table-box */
.table-box {
	&__flex {
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: floor-decimal((440 / map-get($grid-num, xl)) * 100%);
		}
	}
	&__table {
		@include media-breakpoint-up(lg) {
			width: floor-decimal((940 / map-get($grid-num, xl)) * 100%);
		}
	}
}

/*---------------------------------------------------------
 common contents box
---------------------------------------------------------*/

/*---------------------------------------------------------
 free01-box
---------------------------------------------------------*/
// 2~4column
.free01-box {
	margin-bottom: clamp(4rem, 5vw, 5rem);
	&__inner {
		position: relative;
		@include flex-column;
	}
	&__tit {
		order: 2;
		margin-bottom: 1rem;
	}
	&__img {
		order: 1;
		margin-bottom: 1.5rem;
	}
	&__txt {
		order: 3;
		&.is-freebtn {
			//ボタン設置時
			padding-bottom: clamp(6.5rem, 8vw, 8rem);
		}
	}
	.btn-wrap {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

.free01-box__tit {
	padding-left: 20px;
	border-left: 4px double $black;
	@include f-family(font03,serif);
	@include f-w(600);
	@include f-s_xs(1.3, 8);
	@include media-breakpoint-up(xl) {
		padding-left: 30px;
		@include f-size(22);
	}
}
.free01-box__img {
	&__inner {
		@include aspect-img(16, 9);
		background: $b-gray;
	}
}

// 1column
$free01_img_height_lg: 35.13vw; //lg幅の時の画像の高さ (画像の高さ/xl幅)
$free01_img_height_xl: 506px; //xl幅の時の画像の高さ

.free01-box__col1 {
	position: relative;
	&:not(:last-child) {
		margin-bottom: clamp(4rem, 5vw, 5rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: clamp(4rem, 9vw, 9rem);
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: clamp(4rem, 11vw, 11rem);
		}
	}
	&:nth-of-type(odd) {
		@include media-breakpoint-up(lg) {
			.free01-box__col1__img {
				right: 0;
			}
		}
	}
	&:nth-of-type(even) {
		@include media-breakpoint-up(lg) {
			.free01-box__col1__img {
				left: 0;
			}
			.free01-box__col1__txt {
				justify-content: flex-end;
			}
		}
	}
}

.free01-box__col1 {
	&__inner {
		position: relative;
		@include media-breakpoint-up(lg) {
			height: auto;
			min-height: $free01_img_height_lg; //画像の縦幅
		}
		@include media-breakpoint-up(xl) {
			min-height: $free01_img_height_xl; //画像の縦幅
		}
		@include media-breakpoint-down(md) {
			height: 100%;
		}
	}
	&__img {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 62.5%;
			position: absolute;
			top: 0;
			z-index: z-index(module, part03);
		}
		&__inner {
			@include aspect-img;
			@include media-breakpoint-up(lg) {
				@include aspect-img(16, 9);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			position: relative;
			height: auto;
			z-index: z-index(module, part02);
			display: flex;
			min-height: calc($free01_img_height_lg - 5vw);
		}
		@include media-breakpoint-up(xl) {
			min-height: calc($free01_img_height_xl - 3rem); //画像の縦幅
		}
		&__wrap {
			@include media-breakpoint-up(lg) {
				margin-top: 5vw;
				width: 50%;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 3rem;
			}
		}
		&__inner {
			width: 100%;
			background: $white;
			position: relative;
			@include media-breakpoint-up(lg) {
				padding: 3em 10%;
				min-height: 320px;
				margin-top: 5vw;
			}
			@include media-breakpoint-up(xl) {
				padding: 4em 15%;
				margin-top: 3rem;
			}
			@include media-breakpoint-down(md) {
				height: 100%;
				padding: 2em 8%;
			}
		}
	}
	&__tit {
		@include f-s_xs(1, 8);
		@include f-w(600);
		margin-bottom: calc(1rem + 0.3em);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	.txt {
		position: relative;
		width: 100%;
		&.is-freebtn {
			//ボタン設置時
			padding-bottom: clamp(6.5rem, 8vw, 8rem);
		}
	}
	.btn-wrap {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

/* カラムによってタイトル調整 */
.l-col-3-md {
	.free01-box__tit {
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

.l-col-4-sm {
	.free01-box__tit {
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(sm) {
			@include f-s_md(1, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

/*---------------------------------------------------------
 free02-box
---------------------------------------------------------*/
// 2~4column
.free02-box {
	margin-bottom: clamp(4rem, 5vw, 5rem);
	&__inner {
		position: relative;
		@include flex-column;
	}
	&__tit {
		order: 2;
		margin-bottom: 1rem;
	}
	&__img {
		order: 1;
		margin-bottom: 1.5rem;
	}
	&__txt {
		order: 3;
		&.is-freebtn {
			//ボタン設置時
			padding-bottom: clamp(6.5rem, 8vw, 8rem);
		}
	}
	.btn-wrap {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

.free02-box__tit {
	@include f-s_xs(1, 8);
	@include media-breakpoint-up(xl) {
		@include f-size(18);
	}
}
.free02-box__img {
	&__inner {
		@include aspect-img();
	}
}

// 1column
$free02_img_height_lg: 35.13vw; //lg幅の時の画像の高さ (画像の高さ/xl幅)
$free02_img_height_xl: 506px; //xl幅の時の画像の高さ

.free02-box__col1 {
	position: relative;
	&:not(:last-child) {
		margin-bottom: clamp(4rem, 5vw, 5rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: clamp(4rem, 9vw, 9rem);
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: clamp(4rem, 11vw, 11rem);
		}
	}
	&:nth-of-type(odd) {
		@include media-breakpoint-up(lg) {
			.free02-box__col1__img {
				right: 0;
			}
		}
	}
	&:nth-of-type(even) {
		@include media-breakpoint-up(lg) {
			.free02-box__col1__img {
				left: 0;
			}
			.free02-box__col1__txt {
				justify-content: flex-end;
			}
		}
	}
}

.free02-box__col1 {
	&__inner {
		position: relative;
		@include media-breakpoint-up(lg) {
			height: auto;
			min-height: $free02_img_height_lg; //画像の縦幅
		}
		@include media-breakpoint-up(xl) {
			min-height: $free02_img_height_xl; //画像の縦幅
		}
		@include media-breakpoint-down(md) {
			height: 100%;
		}
	}
	&__img {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 62.5%;
			position: absolute;
			top: 0;
			z-index: z-index(module, part03);
		}
		&__inner {
			@include aspect-img;
			@include media-breakpoint-up(lg) {
				@include aspect-img(16, 9);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			position: relative;
			height: auto;
			z-index: z-index(module, part02);
			display: flex;
			min-height: calc($free02_img_height_lg - 5vw);
		}
		@include media-breakpoint-up(xl) {
			min-height: calc($free02_img_height_xl - 3rem); //画像の縦幅
		}
		&__wrap {
			@include media-breakpoint-up(lg) {
				margin-top: 5vw;
				width: 50%;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 3rem;
			}
		}
		&__inner {
			width: 100%;
			background: $white;
			position: relative;
			@include media-breakpoint-up(lg) {
				padding: 3em 10%;
				min-height: 320px;
				margin-top: 5vw;
			}
			@include media-breakpoint-up(xl) {
				padding: 4em 15%;
				margin-top: 3rem;
			}
			@include media-breakpoint-down(md) {
				height: 100%;
				padding: 2em 8%;
			}
		}
	}
	&__tit {
		@include f-s_xs(1, 8);
		@include f-w(600);
		margin-bottom: calc(1rem + 0.3em);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	.txt {
		position: relative;
		width: 100%;
		&.is-freebtn {
			//ボタン設置時
			padding-bottom: clamp(6.5rem, 8vw, 8rem);
		}
	}
	.btn-wrap {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

/* カラムによってタイトル調整 */
.l-col-3-md {
	.free02-box__tit {
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

.l-col-4-sm {
	.free02-box__tit {
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(sm) {
			@include f-s_md(1, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

/*---------------------------------------------------------
 works-box
---------------------------------------------------------*/
.works-box {
	position: relative;
	background: $main_bg;
	margin-bottom: clamp(3rem, 4vw, 4rem);
	text-align: left;
	&__inner {
		@include flex-c-reverse;
	}
	a {
		display: block;
		padding-bottom: 2rem;
		position: relative;
		@include dec-none;
		color: $txt_c;
		height: 100%;
		@include transition;
		@include img-scale;
	}
	&__tit {
		@include f-s_xs(1.2, 4);
		margin-top: 1rem;
		margin-bottom: 0.7rem;
		padding: 15px;
		@include f-family(font03,serif);
		@include f-w(500);
		background: $black;
		color: $white;
		@include media-breakpoint-up(lg) {
			position: relative;
			z-index: 5;
			margin-top: -25px;
			margin-left: -20px;
			width: 90%;
			padding: 15px 15px 15px 25px;
			@include f-size(22);
		}
	}
	&__img {
		overflow: hidden;
		&__inner {
			@include aspect-img;
		}
	}
	&__txt {
		.txt {
			@include media-breakpoint-up(lg) {
				width: 80%;
			}
		}
	}
}

/* カラムによってタイトル調整 */
.l-col-3-md {
	.works-box__tit {
		@include media-breakpoint-up(md) {
			@include f-s_md(1.2, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.2, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

/*---------------------------------------------------------
 blog-box
---------------------------------------------------------*/
/* blog-box1 */
.blog-box1 {
	position: relative;
	background: $white;
	text-align: left;
	@include media-breakpoint-down(xs) {
		margin-bottom: 1.5rem;
	}
	&__inner {
		@include flex-c-reverse;
	}
	a {
		display: block;
		padding-bottom: 2rem;
		position: relative;
		@include dec-none;
		color: $txt_c;
		height: 100%;
		@include transition;
		@include img-scale;
		&:hover {
			box-shadow: 2px 2px 10px $l-gray;
		}
	}
	&__date {
		@include f-family(font02);
		color: $gray;
		@include f-em(16);
	}
	&__tit {
		@include f-s_xs(1, 4);
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__img {
		@include aspect-img;
		overflow: hidden;
	}
}

/* カラムによってタイトル調整 */
.l-col-3-md {
	.blog-box1__tit {
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 2);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

/* blog-box2 */
.blog-box2 {
	position: relative;
	background: $white;
	margin-bottom: 1.5rem;
	text-align: left;
	@include media-breakpoint-up(lg) {
		margin-bottom: 2.5rem;
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		color: $txt_c;
		height: 100%;
		@include transition;
		@include img-scale;
		overflow: hidden;
		&:hover {
			box-shadow: 2px 2px 10px $l-gray;
			.blog-box2__btn span {
				color: $main_c;
				&::after {
					width: 1.75rem;
					background-color: $main_c;
				}
			}
		}
	}
}
.blog-box2 {
	&__inner {
		position: relative;
		@include media-breakpoint-up(md) {
			@include flex-r-reverse;
		}
		@include media-breakpoint-down(sm) {
			@include flex-c-reverse;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 25.53%;
			min-width: 90px;
			flex-shrink: 0;
			flex-grow: 1;
		}
		@include media-breakpoint-up(xl) {
			min-width: 150px;
		}
		&__inner {
			@include media-breakpoint-up(md) {
				width: 100%;
				height: 100%;
				& > div {
					height: 100%;
				}
			}
			@include media-breakpoint-down(sm) {
				@include aspect-img;
			}
		}
	}
	&__txt {
		background: $white;
		position: relative;
		@include media-breakpoint-up(md) {
			flex-basis: 74.47%;
			padding: 1.5rem 3em;
			flex-shrink: 1;
			flex-grow: 1;
		}
		@include media-breakpoint-down(sm) {
			padding-bottom: 3rem;
		}
	}
	&__date {
		@include f-family(font02);
		color: $gray;
		@include f-em(16);
	}
	&__tit {
		margin-bottom: 0.75rem;
		@include f-w(600);
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(md) {
			@include f-s_md(0.85, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__btn {
		position: absolute;
		bottom: 1.5rem;
		right: 0;
		@include f-family(font02);
		color: $gray;
		@include f-em(16);
		padding-right: 2rem;
		span {
			position: relative;
			padding-right: 2rem;
			@include transition;
			&::after {
				content: "";
				position: absolute;
				@include transition;
				transform-origin: left top;
				top: 50%;
				left: calc(100% - 1.25rem);
				width: 1.25rem;
				height: 1px;
				background: $gray;
				display: block;
			}
		}
	}
}

/* トップページの場合は調整 */
.blog-cont__box2-list {
	.blog-box2__inner {
		@include media-breakpoint-up(lg) {
			min-height: 140px;
		}
	}
	.blog-box2__txt {
		@include media-breakpoint-up(lg) {
			padding: 0.75rem 1.5rem;
		}
	}
	.blog-box2__tit {
		@include media-breakpoint-up(lg) {
			@include f-s_lg(0.75, 2);
			margin-bottom: 0.25rem;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(16);
		}
	}
	.blog-box2__btn {
		@include media-breakpoint-up(lg) {
			bottom: 1rem;
		}
	}
}
