/*--------------------------------------------------------------------/
	sns
/--------------------------------------------------------------------*/
// sns side

$side_icon_size: 32px;
.sns-list__side {
	position: fixed;
	top: 40%;
	right: 0.5rem;
	z-index: 3;
	@include media-breakpoint-down(md) {
		display: none;
	}
	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	li {
		padding: 0.4rem 0;
		flex-shrink: 1;
		transform: translateX($side_icon_size + 10px);
		&.active {
			@for $sns_num from 1 through 5 {
				&:nth-child(#{$sns_num}) {
					animation: 0.8s bounceInRight (0.2s + ($sns_num / 10)) forwards;
				}
			}
		}
		a {
			display: block;
			@include dec-none;
			@include transition;
			position: relative;
			line-height: 1;
			width: $side_icon_size;
			height: $side_icon_size;
			border-radius: 50%;
			background: $b-gray;
			overflow: hidden;
			&::before {
				position: absolute;
				color: $white;
				top: 50%;
				left: 52%;
				transform: translate(-50%, -50%);
				font-size: 0.85em;
				@include f-w(100);
				text-align: center;
				vertical-align: middle;
			}
			&:hover {
				transform: scale(1.15, 1.15);
				background: $main_c;
			}
			&.facebook {
				@include icon(facebook2);
				&::before {
					font-size: 1em;
				}
			}
			&.twitter {
				@include icon(twitter);
				&::before {
					font-size: 0.7em;
				}
			}
			&.instagram {
				@include icon(instagram);
			}
			&.Line {
				@include icon(line);
			}
			&.youtube {
				@include icon(youtube);
			}
		}
	}
}

@keyframes bounceInRight {
	to {
		animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
	}
	from {
		transform: translateX($side_icon_size + 10px);
	}
	40% {
		transform: translateX(-16px);
	}
	to {
		transform: translateX(0);
	}
}

// sns sp
$sp_icon_size: 28px;
.sns-list__sp {
	padding: 1.5rem 10% 0;
	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	li {
		padding: 0.3rem;
		flex-shrink: 1;
		a {
			display: block;
			@include dec-none;
			@include transition;
			position: relative;
			line-height: 1;
			width: $side_icon_size;
			height: $side_icon_size;
			border-radius: 50%;
			background: $white;
			overflow: hidden;
			&::before {
				position: absolute;
				color: $b-gray;
				top: 50%;
				left: 52%;
				transform: translate(-50%, -50%);
				font-size: 1em;
				@include f-w(100);
				text-align: center;
				vertical-align: middle;
			}
			&:hover {
				background: $main_c;
			}
			&.facebook {
				@include icon(facebook2);
				&::before {
					font-size: 1.25em;
				}
			}
			&.twitter {
				@include icon(twitter);
				&::before {
					font-size: 1em;
				}
			}
			&.instagram {
				@include icon(instagram);
			}
			&.Line {
				@include icon(line);
			}
			&.youtube {
				@include icon(youtube);
			}
		}
	}
}

// sns footer
.sns-list__footer {
	@include media-breakpoint-up(lg) {
		margin-left: 10%;
	}
	@include media-breakpoint-down(md) {
		margin-top: 1rem;
	}
	a {
		@include f-size(20);
		color: $white;
		@include dec-none;
		padding-left: 2rem;
		position: relative;
		@include f-family(font02);
		@include f-w(400);
		@include transition;
		@include media-breakpoint-down(sm) {
			@include f-size(22);
			padding-left: 2.5rem;
		}
		&:hover {
			color: $gray;
		}
		&::before {
			position: absolute;
			left: 0;
			top: 0.3rem;
			@include f-w(100);
			@include f-em(14);
			@include media-breakpoint-down(sm) {
				top: 0.5rem;
			}
		}
		&.facebook {
			@include icon(facebook);
		}
		&.twitter {
			@include icon(twitter);
		}
		&.instagram {
			@include icon(instagram);
		}
		&.Line {
			@include icon(line);
		}
		&.youtube {
			@include icon(youtube);
		}
	}
}
