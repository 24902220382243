@import "../_spnav/drawer";
.pc-nav {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
.pc-nav__list {
	display: flex;
  justify-content: flex-end;
	& > li {
		&:not(:last-child) {
			margin-right: 1.25rem;
		}
		& > a {
      vertical-align: middle;
			color:$txt_c;
      @include transition;
      @include dec-none;
			position: relative;
			@include fs_lg(13,15);
			@include f-w(400);
			padding-bottom: 7px;
			&::before {
				content:"";
				background-color: $main_c;
				width: 0%;
				height:1px;
				position: absolute;
				bottom:0;
				left:0;
				@include transition;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
    }
    .header.c-header & > a {
      color:$white;
      &::before {
        background-color: $white;
      }
    }
    .header.fixed & > a {
      color:$txt_c;
      &::before {
        background-color: $main_c;
      }
      &:hover {
        color:$main_c;
      }
    }
  }
  &__lv2 {
    position:relative;
    &:hover {
      & ul {
        top: 46px;
        visibility: visible;
        opacity: 1;
      }
    }
    & ul {
      position: absolute;
      @include transition;
      top: 36px;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      left:0;
      z-index: 10;
      width:240px;
      & a {
        display:block;
        @include dec-none;
        padding: .75rem 1rem;
        @include fs_lg(13,15);
        color:$green;
        line-height:1.4;
        position: relative;
        background: $white;
        @include transition;
        &:hover {
          background: $b-gray;
          color: $white;
        }
      }
    }
  }
}



//二階層メニュー
.pc-lv2 {
  position:relative;
  &:hover {
    & ul {
      top: 48px;
      visibility: visible;
      opacity: 1;
    }
  }
  & ul {
    position: absolute;
    @include transition;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    left:0;
    z-index: 10;
    width:240px;
    & a {
      display:block;
      @include dec-none;
      padding: .75rem 1rem;
      @include f-em(14);
      color:$white;
      line-height:1.4;
      position: relative;
      background: $b-gray;
      @include transition;
      &:hover {
        background: $main_c;
        color: $white;
      }
    }
  }
}