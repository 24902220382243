
/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/

html{
	font-size: 100%;
	@include f-base;
}
body {
	@include f-base;
	@include l-sp;
	@include set_font-family;
	@include line-h;
	@include f-w;
	word-wrap: break-word;
	color: $txt_c;
	background: $main_bg;
	overflow-x: hidden;
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/

/* heading 
----------------------------------------------------------------*/
h1,h2,h3,h4,h5,h6{
	line-height: 1.4;
}

/* a,img
----------------------------------------------------------------*/
a{
	color:$link_c;
	vertical-align: top;
	&:link,&:visited {
		text-decoration: underline;
	}
	&:hover,&:active {
		text-decoration: none;
	}
	img {
		display:block;
		width: 100%;
	}
	&.hover {
		img {
			@include transition;
			&:hover {
				opacity: .6;
			}
		}
	} 
	&[href*="javascript:void(0);"] {
		cursor:default;
	}
}

figure {
	img {
		display:block;
		width: 100%;
	}
}

button {
	@include f-base;
}

picture {
	img {
		display:block;
		width: 100%;
	}
}


/* tel-link */
.tel-link {
	a {
		cursor: default;
		@include dec-none;
	}
}
