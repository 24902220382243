/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
	color: $white;
	background: $black;
	padding: 4rem 0 7rem;
	@include media-breakpoint-up(md) {
		padding: 7vw 0;
	}
	@include media-breakpoint-up(xl) {
		padding: 7rem 0;
	}
	.l-cont {
		position: relative;
		@include media-breakpoint-down(md) {
			padding-bottom: 2rem;
		}
	}
}

.f- {
	&flex {
		width: 100%;
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: flex-end;
		}
	}
	&info {
		&__tit {
			margin-bottom: 0.75rem;
			max-width: 374px;
			img {
				width: 100%;
			}
		}
		address {
			width: 100%;
			line-height: 1.5;
		}
	}
	&copy {
		margin-top: 0.5rem;
		@include f-size(14);
		color: $white;
		@include media-breakpoint-down(md) {
			position: absolute;
			bottom: -1rem;
			padding-right:3.5rem;
		}
	}
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
	position: absolute;
	z-index: z-index(pagetop);
	bottom: -4rem;
	right:4%;
	@include media-breakpoint-up(md) {
		bottom: -1rem;
		right:map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		right:map-get($contmargin, pc);
		bottom: 0;
	}
	& a {
		@include dec-none;
		@include transition;
		color: $white;
		&:hover {
			color: $gray;
		}
	}
	i {
		@include f-size(40);
		vertical-align: top;
		@include media-breakpoint-up(md) {
			@include f-size(60);
		}
	}
}
