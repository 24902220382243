
/*--------------------------------------------------------------------/
/* common-banner
-----------------------------------------------------------------*/
.common-banner {
	padding:clamp(3rem, 5vw, 5rem) 0;
	@include media-breakpoint-up(lg) {
		padding:5rem 0;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		li {
			margin-bottom: 1.5em;
		}
	}
	&__item {
		a {
			display: block;
			border:1px solid $l-gray;
			@include transition;
			&:hover {
				opacity: 0.7;
			}
			& img {
				width: 100%;
			}
		}
	}
}
