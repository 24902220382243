@charset "utf-8";
@import "base";
@import "_foundations/foundations";
/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/

@import "_components/loader";  //ローダー
@import "_components/header";  //ヘッダー
@import "_components/nav";     //ナビゲーション
@import "_components/common-contents";//コンテンツ
@import "_components/common-parts"; //汎用 css
@import "_components/common-contact";  //共通CVエリア
@import "_components/common-banner";  //共通バナー
@import "_components/footer";  //フッター
@import "_components/column";  //カラム
@import "_components/sns";  //sns
@import "_components/pagination";  //ページネーション

/* print css
----------------------------------------------------------------*/
@media print {
	.header.fixed{display: none;}
}