/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
@mixin col($col-break, $col-count, $gutter: margin()) {
	$cont-s: inherit;
	@if $col-break == xs {
		$cont-s: map-get($grid-num, xs);
	} @else if $col-break == sm {
		$cont-s: map-get($grid-num, sm);
	} @else if $col-break == md {
		$cont-s: map-get($grid-num, md);
	} @else if $col-break == lg {
		$cont-s: map-get($grid-num, lg);
	} @else if $col-break == xl {
		$cont-s: map-get($grid-num, xl);
	}
	$num: $col-count - 1;
	$margin: floor-decimal((($gutter * $num) / $cont-s) * 100%, 2);
	column-gap: floor-decimal(($margin / $num), 2);
	& > * {
		flex-basis: floor-decimal((100 - $margin) / $col-count);
	}
}

/* 1column */
.l-col-1 {}

// free-contents 1column
.freecont01-cont__list.is-1column,
.freecont02-cont__list.is-1column {
	@include media-breakpoint-between(sm,md) {
		@include flex-wrap;
		@include col(xl, 2, 80);
	}
}

/* 2column */
.l-col-2 {
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(xl, 2, 80);
		}
		@include media-breakpoint-down(xs) {
			& > * {
				@include m-a;
			}
		}
	}
}

.l-col-3 {
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(sm, 2, 30);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(xs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-sm2 {
		//ブログ用
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(sm, 2, 30);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 30);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(xs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-md {
		@include media-breakpoint-up(md) {
			@include flex-wrap;
			@include col(md, 2, 30);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			& > * {
				@include m-a;
			}
		}
	}
	&-lg {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			& > * {
				@include m-a;
			}
		}
	}
}

.l-col-4 {
	@include flex-wrap;
	@include col(xs, 2, 20);
	row-gap: 4vw;
	@include media-breakpoint-up(lg) {
		@include col(lg, 4, 20);
	}
	@include media-breakpoint-up(xl) {
		@include col(xl, 4, 40);
		row-gap: 2.5rem;
	}
	&-xs {
		@include media-breakpoint-up(xs) {
			@include flex-wrap;
			@include col(xs, 2, 40);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 40);
		}
		@include media-breakpoint-down(xxs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(sm, 2, 40);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 40);
		}
	}
	&-md {
		@include media-breakpoint-up(md) {
			@include flex-wrap;
			@include col(md, 2, 30);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 40);
		}
		@include media-breakpoint-down(sm) {
			& > * {
				@include m-a;
			}
		}
	}
}

//ガターなし
.l-col-non-3 {
	@include flex-wrap;
	& > * {
		flex-basis: 50%;
	}
	@include media-breakpoint-up(lg) {
		& > * {
			flex-basis: floor-decimal((100/3) * 1%);
		}
	}
	&-xs {
		//iPhone以上
		@include media-breakpoint-up(xs) {
			@include flex-wrap;
			& > * {
				flex-basis: 50%;
			}
		}
		@include media-breakpoint-up(lg) {
			& > * {
				flex-basis: floor-decimal((100/3) * 1%);
			}
		}
		@include media-breakpoint-down(xxs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-lg {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			& > * {
				flex-basis: floor-decimal((100/3) * 1%);
			}
		}
		@include media-breakpoint-down(md) {
			& > * {
				@include m-a;
			}
		}
	}
}
.l-col-non-4 {
	@include flex-wrap;
	& > * {
		flex-basis: 50%;
	}
	@include media-breakpoint-up(lg) {
		& > * {
			flex-basis: 25%;
		}
	}
	&-xs {
		@include media-breakpoint-up(xs) {
			@include flex-wrap;
			& > * {
				flex-basis: 50%;
			}
		}
		@include media-breakpoint-up(lg) {
			& > * {
				flex-basis: 25%;
			}
		}
	}
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			& > * {
				flex-basis: 50%;
			}
		}
		@include media-breakpoint-up(lg) {
			& > * {
				flex-basis: 25%;
			}
		}
	}
}
