/*--------------------------------------------------------------------/
/* common-contact
---------------------------------------------------------------------*/
.common-contact {
	position: relative;
	overflow: hidden;
	&__inner {
		padding-bottom: 50vw;
		position: relative;
		padding-top: clamp(4rem, 8vw, 8rem);
		@include media-breakpoint-up(lg) {
			padding-bottom: clamp(4rem, 10vw, 10rem);
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16rem;
		}
		@include media-breakpoint-down(md) {
			border-top: 1px solid $m-gray;
		}
	}
	&__txt {
		position: relative;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(lg) {
			width: 50%;
			padding-right: 4rem;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: clamp(5rem, 10vw, 10rem);
		}
	}
	&__tit {
		@extend .cont-tit;
	}
	&__tel {
		position: relative;
	}
	&__num {
		@include f-family(font02);
		@include f-s_xs(1.14, 4);
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		.tel-link {
			@include f-em(28);
			a {
				color: $txt_c !important;
			}
		}
	}
	&__btn {
		margin-top: 3rem;
		@include f-em(20);
		@include media-breakpoint-up(lg) {
			max-width: 540px;
		}
	}
}

.common-contact__img {
	z-index: z-index(module, part03);
	position: absolute;
	bottom: 0;
	@include media-breakpoint-up(lg) {
		width: unquote("min(45vw,864px)");
		height: 75%;
		left: 50%;
		bottom: 5vw;
	}
	@include media-breakpoint-up(xxl) {
		bottom: 8rem;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		left: 0;
	}
	&::after {
		@include media-breakpoint-up(lg) {
			position: absolute;
			display: block;
			left: 12%;
			content: "";
			width: 100%;
			height: 80%;
			background: $other_c1;
			z-index: -1;
			bottom: -8rem;
		}
	}
	&__inner {
		overflow: hidden;
		@include media-breakpoint-only(lg) {
			height: 100%;
		}
		@include media-breakpoint-up(xl) {
			@include aspect-img(5, 4);
		}
		@include media-breakpoint-down(md) {
			@include aspect-img(2, 1);
		}
	}
	img {
		width: 100%;
		display: block;
	}
}
