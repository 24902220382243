@charset "UTF-8";
/* webfont 変数　*/
/* common-title
-----------------------------------------------------*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1536px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1024px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1919px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1535px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1023px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1024px) and (max-width: 1535px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1536px) and (max-width: 1919px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/* ========================================
 * foundation
 ======================================= */
/* html5reset-1.6.1.css */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, address,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  /* added */
  -webkit-text-size-adjust: none;
  /* added */
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

address {
  font-style: normal;
}

nav ul {
  list-style: none;
}

ul li {
  list-style: none;
}

/* added */
ol li {
  list-style: none;
}

/* added */
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  margin: 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?mj1ig3");
  src: url("../fonts/icomoon.eot?mj1ig3#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?mj1ig3") format("truetype"), url("../fonts/icomoon.woff?mj1ig3") format("woff"), url("../fonts/icomoon.svg?mj1ig3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e901";
}

.icon-arrow-down:before {
  content: "\e906";
}

.icon-arrow-left:before {
  content: "\e902";
}

.icon-top_arrow:before {
  content: "\e316";
}

.icon-right_arrow:before {
  content: "\e315";
}

.icon-down_arrow:before {
  content: "\e313";
}

.icon-left_arrow:before {
  content: "\e314";
}

.icon-home:before {
  content: "\e90c";
}

.icon-phone:before {
  content: "\e909";
}

.icon-mail:before {
  content: "\e911";
}

.icon-link:before {
  content: "\e900";
}

.icon-pdf:before {
  content: "\e903";
}

.icon-tag:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e905";
}

.icon-search-plus:before {
  content: "\e908";
}

.icon-lock:before {
  content: "\e90f";
}

.icon-facebook:before {
  content: "\e90a";
}

.icon-facebook2:before {
  content: "\e912";
}

.icon-twitter:before {
  content: "\e90d";
}

.icon-instagram:before {
  content: "\e90b";
}

.icon-line:before {
  content: "\e90e";
}

.icon-youtube:before {
  content: "\e910";
}

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/
html {
  font-size: 100%;
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

body {
  font-size: 14px;
  letter-spacing: 0.1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
  line-height: 1.75;
  font-weight: 400;
  word-wrap: break-word;
  color: #202020;
  background: #F9F4E8;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  body {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 16px;
  }
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/
/* heading 
----------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  line-height: 1.4;
}

/* a,img
----------------------------------------------------------------*/
a {
  color: #D20F0F;
  vertical-align: top;
}

a:link, a:visited {
  text-decoration: underline;
}

a:hover, a:active {
  text-decoration: none;
}

a img {
  display: block;
  width: 100%;
}

a.hover img {
  transition: all 0.3s ease 0s;
}

a.hover img:hover {
  opacity: .6;
}

a[href*="javascript:void(0);"] {
  cursor: default;
}

figure img {
  display: block;
  width: 100%;
}

button {
  font-size: 14px;
}

@media (min-width: 768px) {
  button {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  button {
    font-size: 16px;
  }
}

picture img {
  display: block;
  width: 100%;
}

/* tel-link */
.tel-link a {
  cursor: default;
  text-decoration: none !important;
}

/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/
.loader {
  width: 100vw;
  height: 100vh;
  background: #F1EADD;
  z-index: 12;
  position: fixed;
  transform-origin: right;
}

.loader-block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle {
  width: 0;
  height: 0;
  position: absolute;
  display: block;
  background: #D20F0F;
}

.loader-filter {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 11;
  transform-origin: right;
}

/* sk-chase */
.sk-chase {
  width: 48px;
  height: 48px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot::before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #D20F0F;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1), .sk-chase-dot:nth-child(1)::before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2), .sk-chase-dot:nth-child(2)::before {
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3), .sk-chase-dot:nth-child(3)::before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4), .sk-chase-dot:nth-child(4)::before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5), .sk-chase-dot:nth-child(5)::before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6), .sk-chase-dot:nth-child(6)::before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1);
  }
}

/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.8rem;
  background: #F9F4E8;
  height: 64px;
  transition: all 0.4s ease-in 0s;
}

@media (min-width: 1024px) {
  .header {
    padding-top: 1.2rem;
    padding-bottom: 1.4rem;
    height: 90px;
  }
}

.header.c-header {
  background-color: transparent;
}

.header.fixed {
  background: rgba(249, 244, 232, 0.9);
}

@media (min-width: 1024px) {
  .header.fixed {
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
    height: 64px;
  }
}

@media (max-width: 1023px) {
  .header.fixed {
    height: 64px;
  }
}

.h-inner {
  position: relative;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 5%;
  width: 100%;
}

@media (min-width: 1536px) {
  .h-inner {
    padding: 0 48px;
  }
}

.h-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.h-left {
  width: 70%;
  max-width: 300px;
}

@media (min-width: 1024px) {
  .h-left {
    width: 30%;
    max-width: 320px;
  }
}

.h-logo {
  font-weight: normal;
  letter-spacing: 0;
}

.h-logo img {
  width: 100%;
}

.h-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.h-tel {
  margin-left: 2rem;
  font-family: 'Oswald', sans-serif;
  font-size: 1.25em;
}

@media (max-width: 1535px) {
  .h-tel {
    display: none;
  }
}

.h-tel i {
  font-size: 0.81em;
  color: #D20F0F;
  margin-right: 0.2em;
}

.header.c-header .h-tel {
  color: #fff;
}

.header.c-header .h-tel i {
  color: #fff;
}

.header.fixed .h-tel {
  color: #202020;
}

.header.fixed .h-tel a {
  color: #202020;
}

.header.fixed .h-tel i {
  color: #D20F0F;
}

[id*="link-"],
.scroll-position {
  padding-top: 64px;
  margin-top: -64px;
}

@media (min-width: 1536px) {
  [id*="link-"],
  .scroll-position {
    padding-top: 64px;
    margin-top: -64px;
  }
}

/*--------------------------------------------------------------------/
	drawer
/--------------------------------------------------------------------*/
.header.fixed {
  min-height: 58px;
}

@media (min-width: 1024px) {
  .header.fixed {
    min-height: 72px;
  }
}

@media (max-width: 1535px) {
  #wrapper,
  .header {
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

#wrapper.active,
.header.active {
  transform: translateX(-260px);
}

/* toggle button */
@media (min-width: 1536px) {
  .sp-nav__btn {
    display: none;
  }
}

#sp-nav-toggle {
  display: none;
}

#sp-nav-toggle:checked + .sp-nav-open {
  transform: translateX(-260px);
}

#sp-nav-toggle:checked + .sp-nav-open span, #sp-nav-toggle:checked + .sp-nav-open::before, #sp-nav-toggle:checked + .sp-nav-open::after {
  border-color: #fff;
}

#sp-nav-toggle:checked + .sp-nav-open span {
  transform: scaleX(0);
}

#sp-nav-toggle:checked + .sp-nav-open::before {
  transform: rotate(45deg);
}

#sp-nav-toggle:checked + .sp-nav-open::after {
  transform: rotate(-45deg);
}

#sp-nav-toggle:checked ~ .sp-nav-close {
  pointer-events: auto;
  background: rgba(12, 6, 1, 0.2);
}

#sp-nav-toggle:checked ~ .sp-nav {
  transform: none;
}

.sp-nav-open,
.sp-nav-close {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
}

.sp-nav-open {
  width: 58px;
  height: 58px;
  z-index: 10;
  transition: 0.6s, transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (min-width: 1024px) {
  .sp-nav-open {
    width: 72px;
    height: 72px;
  }
}

.sp-nav-open span, .sp-nav-open::before, .sp-nav-open::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 30%;
  width: 40%;
  border-bottom: 1px solid;
  border-color: #0c0601;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.sp-nav-open::before {
  transform: translateY(-8px);
}

@media (min-width: 1024px) {
  .sp-nav-open::before {
    transform: translateY(-10px);
  }
}

.sp-nav-open::after {
  transform: translateY(8px);
}

@media (min-width: 1024px) {
  .sp-nav-open::after {
    transform: translateY(10px);
  }
}

.sp-nav__btn.c-header .sp-nav-open span,
.sp-nav__btn.c-header .sp-nav-open::before,
.sp-nav__btn.c-header .sp-nav-open::after {
  border-color: #fff;
}

.sp-nav__btn.fixed .sp-nav-open span,
.sp-nav__btn.fixed .sp-nav-open::before,
.sp-nav__btn.fixed .sp-nav-open::after {
  border-color: #0c0601;
}

.sp-nav-close {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: 0.6s;
  z-index: 9;
}

/* drawer menu */
.sp-nav {
  z-index: 10;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: 260px;
  height: 100%;
  background: #232729;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.sp-nav h2 {
  color: #fff;
  text-align: center;
}

.sp-nav__inner {
  overflow-y: auto;
}

.sp-nav__logo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0 1.5em;
}

.sp-nav__logo a {
  padding: 0.7rem 10%;
  display: block;
}

.sp-nav__list {
  line-height: 1.5;
  padding: 0 10%;
}

.sp-nav__list li {
  border-bottom: 1px solid #4B4D4E;
}

.sp-nav__list li:first-child {
  border-top: 1px solid #4B4D4E;
}

.sp-nav__list li a {
  color: #fff;
  display: block;
  padding: 1em 0;
  text-decoration: inherit;
  transition: background 0.6s;
  font-size: 15px;
  font-size: 0.94rem;
  transition: all 0.3s ease 0s;
}

.sp-nav__list li a:hover {
  padding-left: 0.5em;
  color: #979DA4;
}

.sp-nav__tel {
  color: #fff;
  padding: 2rem 10% 2em;
}

.sp-nav__tel p {
  font-size: 13px;
}

.sp-nav__num {
  font-family: 'Oswald', sans-serif;
  font-size: 18px;
}

.sp-nav__num .tel-link {
  font-size: 24px;
  padding-left: 0.2em;
}

.sp-nav__num a {
  color: #fff;
}

@media (max-width: 1535px) {
  .pc-nav {
    display: none;
  }
}

.pc-nav__list {
  display: flex;
  justify-content: flex-end;
}

.pc-nav__list > li:not(:last-child) {
  margin-right: 1.25rem;
}

.pc-nav__list > li > a {
  vertical-align: middle;
  color: #202020;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  position: relative;
  font-size: clamp(13px, 1.3vw, 15px);
  font-weight: 400;
  padding-bottom: 7px;
}

.pc-nav__list > li > a::before {
  content: "";
  background-color: #D20F0F;
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}

.pc-nav__list > li > a:hover::before {
  width: 100%;
}

.header.c-header .pc-nav__list > li > a {
  color: #fff;
}

.header.c-header .pc-nav__list > li > a::before {
  background-color: #fff;
}

.header.fixed .pc-nav__list > li > a {
  color: #202020;
}

.header.fixed .pc-nav__list > li > a::before {
  background-color: #D20F0F;
}

.header.fixed .pc-nav__list > li > a:hover {
  color: #D20F0F;
}

.pc-nav__list__lv2 {
  position: relative;
}

.pc-nav__list__lv2:hover ul {
  top: 46px;
  visibility: visible;
  opacity: 1;
}

.pc-nav__list__lv2 ul {
  position: absolute;
  transition: all 0.3s ease 0s;
  top: 36px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  left: 0;
  z-index: 10;
  width: 240px;
}

.pc-nav__list__lv2 ul a {
  display: block;
  text-decoration: none !important;
  padding: .75rem 1rem;
  font-size: clamp(13px, 1.3vw, 15px);
  color: #5d9e36;
  line-height: 1.4;
  position: relative;
  background: #fff;
  transition: all 0.3s ease 0s;
}

.pc-nav__list__lv2 ul a:hover {
  background: #232729;
  color: #fff;
}

.pc-lv2 {
  position: relative;
}

.pc-lv2:hover ul {
  top: 48px;
  visibility: visible;
  opacity: 1;
}

.pc-lv2 ul {
  position: absolute;
  transition: all 0.3s ease 0s;
  top: 30px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  left: 0;
  z-index: 10;
  width: 240px;
}

.pc-lv2 ul a {
  display: block;
  text-decoration: none !important;
  padding: .75rem 1rem;
  font-size: 0.87em;
  color: #fff;
  line-height: 1.4;
  position: relative;
  background: #232729;
  transition: all 0.3s ease 0s;
}

.pc-lv2 ul a:hover {
  background: #D20F0F;
  color: #fff;
}

/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */
/* main */
.main::after {
  display: block;
  content: "";
  clear: both;
}

.main img {
  display: block;
}

/* contents */
.contents {
  background: #F9F4E8;
}

.l-cont_lg {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8%;
  padding-right: 8%;
}

@media (min-width: 1536px) {
  .l-cont_lg {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.l-cont {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8%;
  padding-right: 8%;
}

@media (min-width: 768px) {
  .l-cont {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 1536px) {
  .l-cont {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.l-cont_xxl {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8%;
  padding-right: 8%;
}

@media (min-width: 768px) {
  .l-cont_xxl {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 1920px) {
  .l-cont_xxl {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.l-cont_full {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8%;
  padding-right: 8%;
}

@media (min-width: 768px) {
  .l-cont_full {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 1536px) {
  .l-cont_full {
    padding-left: 5%;
    padding-right: 5%;
  }
}

/* contents-box */
.l-box {
  width: 100%;
  z-index: 1;
  position: relative;
}

.l-box:not(:last-of-type) {
  margin-bottom: 8rem;
}

@media (min-width: 1024px) {
  .l-box:not(:last-of-type) {
    margin-bottom: 10rem;
  }
}

[class*="bg-box"] {
  padding: 2rem 4%;
}

@media (min-width: 576px) {
  [class*="bg-box"] {
    padding: 2rem 6%;
  }
}

.bg-box--gray {
  background: #f6f6f6;
}

/* table-box */
@media (min-width: 1024px) {
  .table-box__flex {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .table-box__tit {
    width: 30.55%;
  }
}

@media (min-width: 1024px) {
  .table-box__table {
    width: 65.27%;
  }
}

/*---------------------------------------------------------
 common contents box
---------------------------------------------------------*/
/*---------------------------------------------------------
 free01-box
---------------------------------------------------------*/
.free01-box {
  margin-bottom: clamp(4rem, 5vw, 5rem);
}

.free01-box__inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.free01-box__tit {
  order: 2;
  margin-bottom: 1rem;
}

.free01-box__img {
  order: 1;
  margin-bottom: 1.5rem;
}

.free01-box__txt {
  order: 3;
}

.free01-box__txt.is-freebtn {
  padding-bottom: clamp(6.5rem, 8vw, 8rem);
}

.free01-box .btn-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.free01-box__tit {
  padding-left: 20px;
  border-left: 4px double #0c0601;
  font-family: 'Noto Serif JP', serif;
  font-weight: 600;
  font-size: calc(1.3rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .free01-box__tit {
    padding-left: 30px;
    font-size: 1.38rem;
  }
}

.free01-box__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  background: #232729;
}

.free01-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}

.free01-box__img__inner > div,
.free01-box__img__inner > figure,
.free01-box__img__inner > a,
.free01-box__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.free01-box__col1 {
  position: relative;
}

.free01-box__col1:not(:last-child) {
  margin-bottom: clamp(4rem, 5vw, 5rem);
}

@media (min-width: 1024px) {
  .free01-box__col1:not(:last-child) {
    margin-bottom: clamp(4rem, 9vw, 9rem);
  }
}

@media (min-width: 1536px) {
  .free01-box__col1:not(:last-child) {
    margin-bottom: clamp(4rem, 11vw, 11rem);
  }
}

@media (min-width: 1024px) {
  .free01-box__col1:nth-of-type(odd) .free01-box__col1__img {
    right: 0;
  }
}

@media (min-width: 1024px) {
  .free01-box__col1:nth-of-type(even) .free01-box__col1__img {
    left: 0;
  }
  .free01-box__col1:nth-of-type(even) .free01-box__col1__txt {
    justify-content: flex-end;
  }
}

.free01-box__col1__inner {
  position: relative;
}

@media (min-width: 1024px) {
  .free01-box__col1__inner {
    height: auto;
    min-height: 35.13vw;
  }
}

@media (min-width: 1536px) {
  .free01-box__col1__inner {
    min-height: 506px;
  }
}

@media (max-width: 1023px) {
  .free01-box__col1__inner {
    height: 100%;
  }
}

.free01-box__col1__img {
  position: relative;
}

@media (min-width: 1024px) {
  .free01-box__col1__img {
    width: 62.5%;
    position: absolute;
    top: 0;
    z-index: 2;
  }
}

.free01-box__col1__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.free01-box__col1__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.free01-box__col1__img__inner > div,
.free01-box__col1__img__inner > figure,
.free01-box__col1__img__inner > a,
.free01-box__col1__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .free01-box__col1__img__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .free01-box__col1__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .free01-box__col1__img__inner > div,
  .free01-box__col1__img__inner > figure,
  .free01-box__col1__img__inner > a,
  .free01-box__col1__img__inner > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .free01-box__col1__txt {
    position: relative;
    height: auto;
    z-index: 3;
    display: flex;
    min-height: calc($free01_img_height_lg - 5vw);
  }
}

@media (min-width: 1536px) {
  .free01-box__col1__txt {
    min-height: calc($free01_img_height_xl - 3rem);
  }
}

@media (min-width: 1024px) {
  .free01-box__col1__txt__wrap {
    margin-top: 5vw;
    width: 50%;
  }
}

@media (min-width: 1536px) {
  .free01-box__col1__txt__wrap {
    margin-top: 3rem;
  }
}

.free01-box__col1__txt__inner {
  width: 100%;
  background: #fff;
  position: relative;
}

@media (min-width: 1024px) {
  .free01-box__col1__txt__inner {
    padding: 3em 10%;
    min-height: 320px;
    margin-top: 5vw;
  }
}

@media (min-width: 1536px) {
  .free01-box__col1__txt__inner {
    padding: 4em 15%;
    margin-top: 3rem;
  }
}

@media (max-width: 1023px) {
  .free01-box__col1__txt__inner {
    height: 100%;
    padding: 2em 8%;
  }
}

.free01-box__col1__tit {
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
  font-weight: 600;
  margin-bottom: calc(1rem + 0.3em);
}

@media (min-width: 1024px) {
  .free01-box__col1__tit {
    font-size: calc( 1.25rem + 4 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .free01-box__col1__tit {
    font-size: 1.5rem;
  }
}

.free01-box__col1 .txt {
  position: relative;
  width: 100%;
}

.free01-box__col1 .txt.is-freebtn {
  padding-bottom: clamp(6.5rem, 8vw, 8rem);
}

.free01-box__col1 .btn-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* カラムによってタイトル調整 */
.l-col-3-md .free01-box__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 768px) {
  .l-col-3-md .free01-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-3-md .free01-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-3-md .free01-box__tit {
    font-size: 1.13rem;
  }
}

.l-col-4-sm .free01-box__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 576px) {
  .l-col-4-sm .free01-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-4-sm .free01-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-4-sm .free01-box__tit {
    font-size: 1.13rem;
  }
}

/*---------------------------------------------------------
 free02-box
---------------------------------------------------------*/
.free02-box {
  margin-bottom: clamp(4rem, 5vw, 5rem);
}

.free02-box__inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.free02-box__tit {
  order: 2;
  margin-bottom: 1rem;
}

.free02-box__img {
  order: 1;
  margin-bottom: 1.5rem;
}

.free02-box__txt {
  order: 3;
}

.free02-box__txt.is-freebtn {
  padding-bottom: clamp(6.5rem, 8vw, 8rem);
}

.free02-box .btn-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.free02-box__tit {
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .free02-box__tit {
    font-size: 1.13rem;
  }
}

.free02-box__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.free02-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.free02-box__img__inner > div,
.free02-box__img__inner > figure,
.free02-box__img__inner > a,
.free02-box__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.free02-box__col1 {
  position: relative;
}

.free02-box__col1:not(:last-child) {
  margin-bottom: clamp(4rem, 5vw, 5rem);
}

@media (min-width: 1024px) {
  .free02-box__col1:not(:last-child) {
    margin-bottom: clamp(4rem, 9vw, 9rem);
  }
}

@media (min-width: 1536px) {
  .free02-box__col1:not(:last-child) {
    margin-bottom: clamp(4rem, 11vw, 11rem);
  }
}

@media (min-width: 1024px) {
  .free02-box__col1:nth-of-type(odd) .free02-box__col1__img {
    right: 0;
  }
}

@media (min-width: 1024px) {
  .free02-box__col1:nth-of-type(even) .free02-box__col1__img {
    left: 0;
  }
  .free02-box__col1:nth-of-type(even) .free02-box__col1__txt {
    justify-content: flex-end;
  }
}

.free02-box__col1__inner {
  position: relative;
}

@media (min-width: 1024px) {
  .free02-box__col1__inner {
    height: auto;
    min-height: 35.13vw;
  }
}

@media (min-width: 1536px) {
  .free02-box__col1__inner {
    min-height: 506px;
  }
}

@media (max-width: 1023px) {
  .free02-box__col1__inner {
    height: 100%;
  }
}

.free02-box__col1__img {
  position: relative;
}

@media (min-width: 1024px) {
  .free02-box__col1__img {
    width: 62.5%;
    position: absolute;
    top: 0;
    z-index: 2;
  }
}

.free02-box__col1__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.free02-box__col1__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.free02-box__col1__img__inner > div,
.free02-box__col1__img__inner > figure,
.free02-box__col1__img__inner > a,
.free02-box__col1__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .free02-box__col1__img__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .free02-box__col1__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .free02-box__col1__img__inner > div,
  .free02-box__col1__img__inner > figure,
  .free02-box__col1__img__inner > a,
  .free02-box__col1__img__inner > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .free02-box__col1__txt {
    position: relative;
    height: auto;
    z-index: 3;
    display: flex;
    min-height: calc($free02_img_height_lg - 5vw);
  }
}

@media (min-width: 1536px) {
  .free02-box__col1__txt {
    min-height: calc($free02_img_height_xl - 3rem);
  }
}

@media (min-width: 1024px) {
  .free02-box__col1__txt__wrap {
    margin-top: 5vw;
    width: 50%;
  }
}

@media (min-width: 1536px) {
  .free02-box__col1__txt__wrap {
    margin-top: 3rem;
  }
}

.free02-box__col1__txt__inner {
  width: 100%;
  background: #fff;
  position: relative;
}

@media (min-width: 1024px) {
  .free02-box__col1__txt__inner {
    padding: 3em 10%;
    min-height: 320px;
    margin-top: 5vw;
  }
}

@media (min-width: 1536px) {
  .free02-box__col1__txt__inner {
    padding: 4em 15%;
    margin-top: 3rem;
  }
}

@media (max-width: 1023px) {
  .free02-box__col1__txt__inner {
    height: 100%;
    padding: 2em 8%;
  }
}

.free02-box__col1__tit {
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
  font-weight: 600;
  margin-bottom: calc(1rem + 0.3em);
}

@media (min-width: 1024px) {
  .free02-box__col1__tit {
    font-size: calc( 1.25rem + 4 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .free02-box__col1__tit {
    font-size: 1.5rem;
  }
}

.free02-box__col1 .txt {
  position: relative;
  width: 100%;
}

.free02-box__col1 .txt.is-freebtn {
  padding-bottom: clamp(6.5rem, 8vw, 8rem);
}

.free02-box__col1 .btn-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* カラムによってタイトル調整 */
.l-col-3-md .free02-box__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 768px) {
  .l-col-3-md .free02-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-3-md .free02-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-3-md .free02-box__tit {
    font-size: 1.13rem;
  }
}

.l-col-4-sm .free02-box__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 576px) {
  .l-col-4-sm .free02-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-4-sm .free02-box__tit {
    font-size: calc( 1rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-4-sm .free02-box__tit {
    font-size: 1.13rem;
  }
}

/*---------------------------------------------------------
 works-box
---------------------------------------------------------*/
.works-box {
  position: relative;
  background: #F9F4E8;
  margin-bottom: clamp(3rem, 4vw, 4rem);
  text-align: left;
}

.works-box__inner {
  display: flex;
  flex-direction: column-reverse;
}

.works-box a {
  display: block;
  padding-bottom: 2rem;
  position: relative;
  text-decoration: none !important;
  color: #202020;
  height: 100%;
  transition: all 0.3s ease 0s;
}

.works-box a figure {
  overflow: hidden;
}

.works-box a img {
  transition: all 0.3s ease 0s;
}

.works-box a:hover img {
  transform: scale(1.1, 1.1);
}

.works-box__tit {
  font-size: calc(1.2rem + 4 * (100vw - 280px) / 1160);
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  padding: 15px;
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
  background: #0c0601;
  color: #fff;
}

@media (min-width: 1024px) {
  .works-box__tit {
    position: relative;
    z-index: 5;
    margin-top: -25px;
    margin-left: -20px;
    width: 90%;
    padding: 15px 15px 15px 25px;
    font-size: 1.38rem;
  }
}

.works-box__img {
  overflow: hidden;
}

.works-box__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.works-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.works-box__img__inner > div,
.works-box__img__inner > figure,
.works-box__img__inner > a,
.works-box__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .works-box__txt .txt {
    width: 80%;
  }
}

/* カラムによってタイトル調整 */
@media (min-width: 768px) {
  .l-col-3-md .works-box__tit {
    font-size: calc( 1.2rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-3-md .works-box__tit {
    font-size: calc( 1.2rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-3-md .works-box__tit {
    font-size: 1.5rem;
  }
}

/*---------------------------------------------------------
 blog-box
---------------------------------------------------------*/
/* blog-box1 */
.blog-box1 {
  position: relative;
  background: #fff;
  text-align: left;
}

@media (max-width: 575px) {
  .blog-box1 {
    margin-bottom: 1.5rem;
  }
}

.blog-box1__inner {
  display: flex;
  flex-direction: column-reverse;
}

.blog-box1 a {
  display: block;
  padding-bottom: 2rem;
  position: relative;
  text-decoration: none !important;
  color: #202020;
  height: 100%;
  transition: all 0.3s ease 0s;
}

.blog-box1 a figure {
  overflow: hidden;
}

.blog-box1 a img {
  transition: all 0.3s ease 0s;
}

.blog-box1 a:hover img {
  transform: scale(1.1, 1.1);
}

.blog-box1 a:hover {
  box-shadow: 2px 2px 10px #e0e4e6;
}

.blog-box1__date {
  font-family: 'Oswald', sans-serif;
  color: #979DA4;
  font-size: 1em;
}

.blog-box1__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1536px) {
  .blog-box1__tit {
    font-size: 1.13rem;
  }
}

.blog-box1__img {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.blog-box1__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.blog-box1__img > div,
.blog-box1__img > figure,
.blog-box1__img > a,
.blog-box1__img > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* カラムによってタイトル調整 */
@media (min-width: 768px) {
  .l-col-3-md .blog-box1__tit {
    font-size: calc( 1rem + 2 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1024px) {
  .l-col-3-md .blog-box1__tit {
    font-size: calc( 1rem + 2 * (100vw - 1000px) / 440);
  }
}

@media (min-width: 1536px) {
  .l-col-3-md .blog-box1__tit {
    font-size: 1.13rem;
  }
}

/* blog-box2 */
.blog-box2 {
  position: relative;
  background: #fff;
  margin-bottom: 1.5rem;
  text-align: left;
}

@media (min-width: 1024px) {
  .blog-box2 {
    margin-bottom: 2.5rem;
  }
}

.blog-box2 a {
  display: block;
  position: relative;
  text-decoration: none !important;
  color: #202020;
  height: 100%;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}

.blog-box2 a figure {
  overflow: hidden;
}

.blog-box2 a img {
  transition: all 0.3s ease 0s;
}

.blog-box2 a:hover img {
  transform: scale(1.1, 1.1);
}

.blog-box2 a:hover {
  box-shadow: 2px 2px 10px #e0e4e6;
}

.blog-box2 a:hover .blog-box2__btn span {
  color: #D20F0F;
}

.blog-box2 a:hover .blog-box2__btn span::after {
  width: 1.75rem;
  background-color: #D20F0F;
}

.blog-box2__inner {
  position: relative;
}

@media (min-width: 768px) {
  .blog-box2__inner {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media (max-width: 767px) {
  .blog-box2__inner {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .blog-box2__img {
    width: 25.53%;
    min-width: 90px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

@media (min-width: 1536px) {
  .blog-box2__img {
    min-width: 150px;
  }
}

@media (min-width: 768px) {
  .blog-box2__img__inner {
    width: 100%;
    height: 100%;
  }
  .blog-box2__img__inner > div {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .blog-box2__img__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .blog-box2__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .blog-box2__img__inner > div,
  .blog-box2__img__inner > figure,
  .blog-box2__img__inner > a,
  .blog-box2__img__inner > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.blog-box2__txt {
  background: #fff;
  position: relative;
}

@media (min-width: 768px) {
  .blog-box2__txt {
    flex-basis: 74.47%;
    padding: 1.5rem 3em;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

@media (max-width: 767px) {
  .blog-box2__txt {
    padding-bottom: 3rem;
  }
}

.blog-box2__date {
  font-family: 'Oswald', sans-serif;
  color: #979DA4;
  font-size: 1em;
}

.blog-box2__tit {
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 768px) {
  .blog-box2__tit {
    font-size: calc( 0.85rem + 4 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1536px) {
  .blog-box2__tit {
    font-size: 1.13rem;
  }
}

.blog-box2__btn {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  font-family: 'Oswald', sans-serif;
  color: #979DA4;
  font-size: 1em;
  padding-right: 2rem;
}

.blog-box2__btn span {
  position: relative;
  padding-right: 2rem;
  transition: all 0.3s ease 0s;
}

.blog-box2__btn span::after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  transform-origin: left top;
  top: 50%;
  left: calc(100% - 1.25rem);
  width: 1.25rem;
  height: 1px;
  background: #979DA4;
  display: block;
}

/* トップページの場合は調整 */
@media (min-width: 1024px) {
  .blog-cont__box2-list .blog-box2__inner {
    min-height: 140px;
  }
}

@media (min-width: 1024px) {
  .blog-cont__box2-list .blog-box2__txt {
    padding: 0.75rem 1.5rem;
  }
}

@media (min-width: 1024px) {
  .blog-cont__box2-list .blog-box2__tit {
    font-size: calc( 0.75rem + 2 * (100vw - 1000px) / 440);
    margin-bottom: 0.25rem;
  }
}

@media (min-width: 1536px) {
  .blog-cont__box2-list .blog-box2__tit {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .blog-cont__box2-list .blog-box2__btn {
    bottom: 1rem;
  }
}

/*----------------------------------------------------------------
	common-parts
----------------------------------------------------------------*/
/*	heading
/--------------------------------------------------------------------*/
.cont-tit, .common-contact__tit {
  margin-bottom: calc(2rem + 0.75em);
  line-height: 1.4;
}

.cont-tit b, .common-contact__tit b {
  font-size: calc(2rem + 32 * (100vw - 280px) / 1160);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
  display: block;
  color: #D20F0F;
}

@media (min-width: 1536px) {
  .cont-tit b, .common-contact__tit b {
    font-size: 4rem;
  }
}

.cont-tit span, .common-contact__tit span {
  font-family: 'Noto Serif JP', serif;
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .cont-tit span, .common-contact__tit span {
    font-size: 1.5rem;
  }
}

.cont-tit--center {
  margin-bottom: calc(2rem + 0.75em);
  line-height: 1.4;
  text-align: center;
}

.cont-tit--center b {
  font-size: calc(2rem + 32 * (100vw - 280px) / 1160);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
  display: block;
  color: #D20F0F;
}

@media (min-width: 1536px) {
  .cont-tit--center b {
    font-size: 4rem;
  }
}

.cont-tit--center span {
  font-family: 'Noto Serif JP', serif;
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .cont-tit--center span {
    font-size: 1.5rem;
  }
}

.cont-tit2 {
  margin-bottom: calc(2rem + 0.6em);
  line-height: 1.4;
}

.cont-tit2 b {
  font-size: calc(1.5rem + 24 * (100vw - 280px) / 1160);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
  display: block;
  color: #D20F0F;
}

@media (min-width: 1536px) {
  .cont-tit2 b {
    font-size: 3rem;
  }
}

.cont-tit2 span {
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .cont-tit2 span {
    font-size: 1.38rem;
  }
}

.cont-tit2--center {
  margin-bottom: calc(2rem + 0.6em);
  line-height: 1.4;
  text-align: center;
}

.cont-tit2--center b {
  font-size: calc(1.5rem + 24 * (100vw - 280px) / 1160);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
  display: block;
  color: #D20F0F;
}

@media (min-width: 1536px) {
  .cont-tit2--center b {
    font-size: 3rem;
  }
}

.cont-tit2--center span {
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .cont-tit2--center span {
    font-size: 1.38rem;
  }
}

.heading-tit, .table-tit {
  text-align: center;
  line-height: 1.4;
  padding-bottom: calc(0.5rem + 0.3em);
  margin-bottom: calc(2rem + 0.3em);
  position: relative;
  font-weight: 400;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .heading-tit, .table-tit {
    font-size: 1.5rem;
  }
}

.heading-tit::after, .table-tit::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  background-color: #D20F0F;
  width: 3rem;
  height: 1px;
}

[class*="heading-tit2"] {
  text-align: center;
  line-height: 1.4;
  margin-bottom: calc(2rem + 0.3em);
  font-weight: 400;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  [class*="heading-tit2"] {
    font-size: 1.5rem;
  }
}

[class*="heading-tit2"] b {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  display: block;
  font-size: calc(1.4rem + 10 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  [class*="heading-tit2"] b {
    font-size: 2rem;
  }
}

[class*="heading-tit2"] small {
  display: inline-block;
  position: relative;
  padding: 0 1.5em;
  font-size: 0.68em;
}

[class*="heading-tit2"] small::before, [class*="heading-tit2"] small::after {
  position: absolute;
  width: 1em;
  height: 1px;
  content: "";
  top: calc(50% + 1px);
}

[class*="heading-tit2"] small::before {
  left: 0;
}

[class*="heading-tit2"] small::after {
  right: 0;
}

/* teble用のタイトル */
/* ol
/--------------------------------------------------------------------*/
.common-ol {
  margin-left: 5px;
  counter-reset: ol-num;
}

.common-ol > li {
  line-height: 1.4;
  padding-left: 1.5em;
  text-indent: -1.5em;
  position: relative;
}

.common-ol > li::before {
  counter-increment: ol-num;
  content: counter(ol-num) ".";
  padding-right: 0.5em;
  text-align: center;
}

.common-ol > li:not(:last-child) {
  margin-bottom: 0.7rem;
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
  text-align: left;
}

.common-ul li {
  line-height: 1.5;
  padding-left: 1.5rem;
  position: relative;
}

.common-ul li::before {
  position: absolute;
  content: "●";
  left: 0;
  top: 0.2em;
  color: #D20F0F;
  font-size: 0.62em;
}

.common-ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.common-ul2 {
  margin-left: 5px;
  text-align: left;
}

.common-ul2 li {
  line-height: 1.5;
  padding-left: 1rem;
  position: relative;
}

.common-ul2 li::before {
  content: "・";
  position: absolute;
  color: #202020;
  left: 0;
  top: 0;
}

.common-ul2 li:not(:last-child) {
  margin-bottom: 0.3rem;
}

.caution-ul {
  margin-left: 5px;
}

.caution-ul li {
  line-height: 1.5;
  padding-left: 1.2rem;
  position: relative;
  color: #D93838;
}

.caution-ul li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}

.caution-ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
  margin-left: 5px;
  margin-bottom: 2rem;
}

.common-dl dt {
  line-height: 1.5;
}

.common-dl dt:before {
  content: "●";
  padding-right: 0.3em;
}

.common-dl dd:not(:last-child) {
  margin-bottom: 1.5rem;
}

.common-dl2 {
  margin-bottom: 1rem;
}

.common-dl2 dt {
  line-height: 1.5;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #c9ced2;
  margin-bottom: 0.7rem;
  font-size: 1.12em;
  font-weight: 500;
}

.common-dl2 dd:not(:last-child) {
  margin-bottom: 2rem;
}

/* table
----------------------------------------------------------------*/
.common-table {
  width: 100%;
  font-size: 0.93em;
}

.common-table tr {
  border-bottom: 1px solid #232729;
}

.common-table tr:first-child {
  border-top: 1px solid #232729;
}

.common-table th,
.common-table td.column-1 {
  text-align: left;
  font-weight: normal;
  padding: 1rem 0;
  vertical-align: center;
}

@media (min-width: 768px) {
  .common-table th,
  .common-table td.column-1 {
    display: table-cell;
    width: 25%;
    min-width: 180px;
    padding: 1.25rem 0;
  }
}

@media (min-width: 1536px) {
  .common-table th,
  .common-table td.column-1 {
    width: 225px;
  }
}

@media (max-width: 767px) {
  .common-table th,
  .common-table td.column-1 {
    display: block;
    width: 100%;
    padding-bottom: 0;
  }
}

.common-table td {
  vertical-align: center;
  padding: 1rem 1.25rem 1rem 0.5rem;
}

@media (min-width: 768px) {
  .common-table td {
    display: table-cell;
    padding: 1rem 1.25rem 1rem 0.5rem;
  }
}

@media (max-width: 767px) {
  .common-table td {
    display: block;
    width: 100%;
    padding-top: 0;
    padding-left: 0;
  }
}

.common-table2 {
  width: 100%;
  font-size: 0.93em;
  margin-bottom: 2rem;
}

.common-table2 tr {
  border-bottom: 2px solid #F1EADD;
}

.common-table2 th,
.common-table2 td.column-1 {
  text-align: left;
  font-weight: normal;
  padding: 1rem 5%;
  color: #fff;
  background: #232729;
}

@media (min-width: 1024px) {
  .common-table2 th,
  .common-table2 td.column-1 {
    padding: 1rem 1.5rem;
    width: 25%;
    min-width: 180px;
  }
}

@media (min-width: 1536px) {
  .common-table2 th,
  .common-table2 td.column-1 {
    width: 225px;
  }
}

@media (max-width: 1023px) {
  .common-table2 th,
  .common-table2 td.column-1 {
    display: block;
  }
}

.common-table2 td {
  padding: 1rem 5%;
}

@media (min-width: 1024px) {
  .common-table2 td {
    background-color: #fff;
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 1023px) {
  .common-table2 td {
    display: block;
  }
}

/* table press CSS */
.tablepress td,
.tablepress th {
  padding: none;
}

.tablepress tbody td,
.tablepress tfoot th {
  border-top: none !important;
}

/* cotegory */
.category-list {
  line-height: 1.4;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .category-list {
    display: flex;
    align-items: center;
  }
  .category-list .category-list__items {
    margin-top: .5rem;
  }
}

.category-list__tit {
  min-width: 110px;
}

@media (min-width: 768px) {
  .category-list__tit {
    padding-right: 1rem;
    position: relative;
    padding-top: 0.1rem;
    margin-right: 0.5rem;
  }
}

@media (max-width: 767px) {
  .category-list__tit {
    margin-bottom: 0.5rem;
  }
}

.category-list__items {
  font-size: 0;
}

.category-list__items > * {
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  display: inline-block;
  font-size: 1em;
}

@media (min-width: 1024px) {
  .category-list__items > * {
    margin-right: 0.5rem;
  }
}

.category-list [class*="category-item"] {
  font-size: 0.81rem;
  padding: 0.2rem .7rem;
  min-width: 90px;
  vertical-align: top;
  margin-bottom: 0.4rem;
}

@media (min-width: 1024px) {
  .category-list [class*="category-item"] {
    min-width: 140px;
    padding: 0.3rem 1rem;
  }
}

[class*="category-item"] {
  display: inline-block;
  background-color: #fff;
  color: #979DA4;
  text-align: center;
  line-height: 1.4;
  padding: 0.3rem 1.25em;
  font-size: 0.81rem;
  border-radius: 24px;
  letter-spacing: 0.05em;
}

a[class*="category-item"] {
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}

a[class*="category-item"]:hover {
  filter: saturate(200%);
}

.category-item.is-black {
  color: #fff;
  background: #0c0601;
}

.category-item.is-black:hover {
  background: #4B4D4E;
}

/* txt */
.txt p {
  margin-bottom: 1rem;
}

.txt p:last-child {
  margin-bottom: 0;
}

/* align */
.align-right {
  float: right;
  margin: 0 0 0.7rem 0.7rem;
}

.align-left {
  float: left;
  margin: 0.7rem 0.7rem 0 0;
}

.align-center {
  display: block;
  margin: 0 auto 0.7rem;
}

/* Preload images */
body:after {
  content: url(../img/common/close.png) url(../img/common/loading.gif) url(../img/common/prev.png) url(../img/common/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/common/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/common/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/common/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  padding-top: .5rem;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/common/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/*--------------------------------------------------------------------/
/* common-contact
---------------------------------------------------------------------*/
.common-contact {
  position: relative;
  overflow: hidden;
}

.common-contact__inner {
  padding-bottom: 50vw;
  position: relative;
  padding-top: clamp(4rem, 8vw, 8rem);
}

@media (min-width: 1024px) {
  .common-contact__inner {
    padding-bottom: clamp(4rem, 10vw, 10rem);
  }
}

@media (min-width: 1536px) {
  .common-contact__inner {
    padding-bottom: 16rem;
  }
}

@media (max-width: 1023px) {
  .common-contact__inner {
    border-top: 1px solid #c9ced2;
  }
}

.common-contact__txt {
  position: relative;
  z-index: 4;
}

@media (min-width: 1024px) {
  .common-contact__txt {
    width: 50%;
    padding-right: 4rem;
  }
}

@media (max-width: 1023px) {
  .common-contact__txt {
    padding-bottom: clamp(5rem, 10vw, 10rem);
  }
}

.common-contact__tel {
  position: relative;
}

.common-contact__num {
  font-family: 'Oswald', sans-serif;
  font-size: calc(1.14rem + 4 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .common-contact__num {
    font-size: 1.38rem;
  }
}

.common-contact__num .tel-link {
  font-size: 1.75em;
}

.common-contact__num .tel-link a {
  color: #202020 !important;
}

.common-contact__btn {
  margin-top: 3rem;
  font-size: 1.25em;
}

@media (min-width: 1024px) {
  .common-contact__btn {
    max-width: 540px;
  }
}

.common-contact__img {
  z-index: 2;
  position: absolute;
  bottom: 0;
}

@media (min-width: 1024px) {
  .common-contact__img {
    width: min(45vw,864px);
    height: 75%;
    left: 50%;
    bottom: 5vw;
  }
}

@media (min-width: 1920px) {
  .common-contact__img {
    bottom: 8rem;
  }
}

@media (max-width: 1023px) {
  .common-contact__img {
    width: 100%;
    left: 0;
  }
}

@media (min-width: 1024px) {
  .common-contact__img::after {
    position: absolute;
    display: block;
    left: 12%;
    content: "";
    width: 100%;
    height: 80%;
    background: #AA0000;
    z-index: -1;
    bottom: -8rem;
  }
}

.common-contact__img__inner {
  overflow: hidden;
}

@media (min-width: 1024px) and (max-width: 1535px) {
  .common-contact__img__inner {
    height: 100%;
  }
}

@media (min-width: 1536px) {
  .common-contact__img__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .common-contact__img__inner::before {
    content: "";
    display: block;
    padding-top: 80%;
    position: relative;
  }
  .common-contact__img__inner > div,
  .common-contact__img__inner > figure,
  .common-contact__img__inner > a,
  .common-contact__img__inner > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .common-contact__img__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .common-contact__img__inner::before {
    content: "";
    display: block;
    padding-top: 50%;
    position: relative;
  }
  .common-contact__img__inner > div,
  .common-contact__img__inner > figure,
  .common-contact__img__inner > a,
  .common-contact__img__inner > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.common-contact__img img {
  width: 100%;
  display: block;
}

/*--------------------------------------------------------------------/
/* common-banner
-----------------------------------------------------------------*/
.common-banner {
  padding: clamp(3rem, 5vw, 5rem) 0;
}

@media (min-width: 1024px) {
  .common-banner {
    padding: 5rem 0;
  }
}

.common-banner__list {
  display: flex;
  flex-wrap: wrap;
}

.common-banner__list li {
  margin-bottom: 1.5em;
}

.common-banner__item a {
  display: block;
  border: 1px solid #e0e4e6;
  transition: all 0.3s ease 0s;
}

.common-banner__item a:hover {
  opacity: 0.7;
}

.common-banner__item a img {
  width: 100%;
}

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
  color: #fff;
  background: #0c0601;
  padding: 4rem 0 7rem;
}

@media (min-width: 768px) {
  footer {
    padding: 7vw 0;
  }
}

@media (min-width: 1536px) {
  footer {
    padding: 7rem 0;
  }
}

footer .l-cont {
  position: relative;
}

@media (max-width: 1023px) {
  footer .l-cont {
    padding-bottom: 2rem;
  }
}

.f-flex {
  width: 100%;
}

@media (min-width: 1024px) {
  .f-flex {
    display: flex;
    align-items: flex-end;
  }
}

.f-info__tit {
  margin-bottom: 0.75rem;
  max-width: 374px;
}

.f-info__tit img {
  width: 100%;
}

.f-info address {
  width: 100%;
  line-height: 1.5;
}

.f-copy {
  margin-top: 0.5rem;
  font-size: 0.88rem;
  color: #fff;
}

@media (max-width: 1023px) {
  .f-copy {
    position: absolute;
    bottom: -1rem;
    padding-right: 3.5rem;
  }
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
  position: absolute;
  z-index: 7;
  bottom: -4rem;
  right: 4%;
}

@media (min-width: 768px) {
  .pagetop {
    bottom: -1rem;
    right: 8%;
  }
}

@media (min-width: 1536px) {
  .pagetop {
    right: 48px;
    bottom: 0;
  }
}

.pagetop a {
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  color: #fff;
}

.pagetop a:hover {
  color: #979DA4;
}

.pagetop i {
  font-size: 2.5rem;
  vertical-align: top;
}

@media (min-width: 768px) {
  .pagetop i {
    font-size: 3.75rem;
  }
}

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
/* 1column */
@media (min-width: 576px) and (max-width: 1023px) {
  .freecont01-cont__list.is-1column,
  .freecont02-cont__list.is-1column {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5.55%;
  }
  .freecont01-cont__list.is-1column > *,
  .freecont02-cont__list.is-1column > * {
    flex-basis: 47.22%;
  }
}

/* 2column */
@media (min-width: 576px) {
  .l-col-2-sm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5.55%;
  }
  .l-col-2-sm > * {
    flex-basis: 47.22%;
  }
}

@media (max-width: 575px) {
  .l-col-2-sm > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .l-col-3-sm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5.2%;
  }
  .l-col-3-sm > * {
    flex-basis: 47.4%;
  }
}

@media (min-width: 1024px) {
  .l-col-3-sm {
    column-gap: 4%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1536px) {
  .l-col-3-sm {
    column-gap: 4.16%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.55%;
  }
}

@media (max-width: 575px) {
  .l-col-3-sm > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .l-col-3-sm2 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5.2%;
  }
  .l-col-3-sm2 > * {
    flex-basis: 47.4%;
  }
}

@media (min-width: 1024px) {
  .l-col-3-sm2 {
    column-gap: 3%;
  }
  .l-col-3-sm2 > * {
    flex-basis: 31.33%;
  }
}

@media (min-width: 1536px) {
  .l-col-3-sm2 {
    column-gap: 4.16%;
  }
  .l-col-3-sm2 > * {
    flex-basis: 30.55%;
  }
}

@media (max-width: 575px) {
  .l-col-3-sm2 > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .l-col-3-md {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.9%;
  }
  .l-col-3-md > * {
    flex-basis: 48.05%;
  }
}

@media (min-width: 1024px) {
  .l-col-3-md {
    column-gap: 4%;
  }
  .l-col-3-md > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1536px) {
  .l-col-3-md {
    column-gap: 4.16%;
  }
  .l-col-3-md > * {
    flex-basis: 30.55%;
  }
}

@media (max-width: 767px) {
  .l-col-3-md > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .l-col-3-lg {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1536px) {
  .l-col-3-lg {
    column-gap: 4.16%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.55%;
  }
}

@media (max-width: 767px) {
  .l-col-3-lg > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.l-col-4 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5.31%;
  row-gap: 4vw;
}

.l-col-4 > * {
  flex-basis: 47.34%;
}

@media (min-width: 1024px) {
  .l-col-4 {
    column-gap: 2%;
  }
  .l-col-4 > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1536px) {
  .l-col-4 {
    column-gap: 2.77%;
    row-gap: 2.5rem;
  }
  .l-col-4 > * {
    flex-basis: 22.91%;
  }
}

@media (min-width: 376px) {
  .l-col-4-xs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10.63%;
  }
  .l-col-4-xs > * {
    flex-basis: 44.68%;
  }
}

@media (min-width: 1024px) {
  .l-col-4-xs {
    column-gap: 2%;
  }
  .l-col-4-xs > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1536px) {
  .l-col-4-xs {
    column-gap: 2.77%;
  }
  .l-col-4-xs > * {
    flex-basis: 22.91%;
  }
}

@media (max-width: 375px) {
  .l-col-4-xs > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .l-col-4-sm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 6.94%;
  }
  .l-col-4-sm > * {
    flex-basis: 46.53%;
  }
}

@media (min-width: 1024px) {
  .l-col-4-sm {
    column-gap: 2%;
  }
  .l-col-4-sm > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1536px) {
  .l-col-4-sm {
    column-gap: 2.77%;
  }
  .l-col-4-sm > * {
    flex-basis: 22.91%;
  }
}

@media (min-width: 768px) {
  .l-col-4-md {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.9%;
  }
  .l-col-4-md > * {
    flex-basis: 48.05%;
  }
}

@media (min-width: 1024px) {
  .l-col-4-md {
    column-gap: 2%;
  }
  .l-col-4-md > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1536px) {
  .l-col-4-md {
    column-gap: 2.77%;
  }
  .l-col-4-md > * {
    flex-basis: 22.91%;
  }
}

@media (max-width: 767px) {
  .l-col-4-md > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.l-col-non-3 {
  display: flex;
  flex-wrap: wrap;
}

.l-col-non-3 > * {
  flex-basis: 50%;
}

@media (min-width: 1024px) {
  .l-col-non-3 > * {
    flex-basis: 33.33%;
  }
}

@media (min-width: 376px) {
  .l-col-non-3-xs {
    display: flex;
    flex-wrap: wrap;
  }
  .l-col-non-3-xs > * {
    flex-basis: 50%;
  }
}

@media (min-width: 1024px) {
  .l-col-non-3-xs > * {
    flex-basis: 33.33%;
  }
}

@media (max-width: 375px) {
  .l-col-non-3-xs > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .l-col-non-3-lg {
    display: flex;
    flex-wrap: wrap;
  }
  .l-col-non-3-lg > * {
    flex-basis: 33.33%;
  }
}

@media (max-width: 1023px) {
  .l-col-non-3-lg > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.l-col-non-4 {
  display: flex;
  flex-wrap: wrap;
}

.l-col-non-4 > * {
  flex-basis: 50%;
}

@media (min-width: 1024px) {
  .l-col-non-4 > * {
    flex-basis: 25%;
  }
}

@media (min-width: 376px) {
  .l-col-non-4-xs {
    display: flex;
    flex-wrap: wrap;
  }
  .l-col-non-4-xs > * {
    flex-basis: 50%;
  }
}

@media (min-width: 1024px) {
  .l-col-non-4-xs > * {
    flex-basis: 25%;
  }
}

@media (min-width: 576px) {
  .l-col-non-4-sm {
    display: flex;
    flex-wrap: wrap;
  }
  .l-col-non-4-sm > * {
    flex-basis: 50%;
  }
}

@media (min-width: 1024px) {
  .l-col-non-4-sm > * {
    flex-basis: 25%;
  }
}

/*--------------------------------------------------------------------/
	sns
/--------------------------------------------------------------------*/
.sns-list__side {
  position: fixed;
  top: 40%;
  right: 0.5rem;
  z-index: 3;
}

@media (max-width: 1023px) {
  .sns-list__side {
    display: none;
  }
}

.sns-list__side ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sns-list__side li {
  padding: 0.4rem 0;
  flex-shrink: 1;
  transform: translateX(42px);
}

.sns-list__side li.active:nth-child(1) {
  animation: 0.8s bounceInRight 0.3s forwards;
}

.sns-list__side li.active:nth-child(2) {
  animation: 0.8s bounceInRight 0.4s forwards;
}

.sns-list__side li.active:nth-child(3) {
  animation: 0.8s bounceInRight 0.5s forwards;
}

.sns-list__side li.active:nth-child(4) {
  animation: 0.8s bounceInRight 0.6s forwards;
}

.sns-list__side li.active:nth-child(5) {
  animation: 0.8s bounceInRight 0.7s forwards;
}

.sns-list__side li a {
  display: block;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  position: relative;
  line-height: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #232729;
  overflow: hidden;
}

.sns-list__side li a::before {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 0.85em;
  font-weight: 100;
  text-align: center;
  vertical-align: middle;
}

.sns-list__side li a:hover {
  transform: scale(1.15, 1.15);
  background: #D20F0F;
}

.sns-list__side li a.facebook:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__side li a.facebook::before {
  font-size: 1em;
}

.sns-list__side li a.twitter:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__side li a.twitter::before {
  font-size: 0.7em;
}

.sns-list__side li a.instagram:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__side li a.Line:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__side li a.youtube:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes bounceInRight {
  to {
    animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
  }
  from {
    transform: translateX(42px);
  }
  40% {
    transform: translateX(-16px);
  }
  to {
    transform: translateX(0);
  }
}

.sns-list__sp {
  padding: 1.5rem 10% 0;
}

.sns-list__sp ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sns-list__sp li {
  padding: 0.3rem;
  flex-shrink: 1;
}

.sns-list__sp li a {
  display: block;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  position: relative;
  line-height: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}

.sns-list__sp li a::before {
  position: absolute;
  color: #232729;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  font-weight: 100;
  text-align: center;
  vertical-align: middle;
}

.sns-list__sp li a:hover {
  background: #D20F0F;
}

.sns-list__sp li a.facebook:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__sp li a.facebook::before {
  font-size: 1.25em;
}

.sns-list__sp li a.twitter:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__sp li a.twitter::before {
  font-size: 1em;
}

.sns-list__sp li a.instagram:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__sp li a.Line:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__sp li a.youtube:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) {
  .sns-list__footer {
    margin-left: 10%;
  }
}

@media (max-width: 1023px) {
  .sns-list__footer {
    margin-top: 1rem;
  }
}

.sns-list__footer a {
  font-size: 1.25rem;
  color: #fff;
  text-decoration: none !important;
  padding-left: 2rem;
  position: relative;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  transition: all 0.3s ease 0s;
}

@media (max-width: 767px) {
  .sns-list__footer a {
    font-size: 1.38rem;
    padding-left: 2.5rem;
  }
}

.sns-list__footer a:hover {
  color: #979DA4;
}

.sns-list__footer a::before {
  position: absolute;
  left: 0;
  top: 0.3rem;
  font-weight: 100;
  font-size: 0.87em;
}

@media (max-width: 767px) {
  .sns-list__footer a::before {
    top: 0.5rem;
  }
}

.sns-list__footer a.facebook:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__footer a.twitter:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__footer a.instagram:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__footer a.Line:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__footer a.youtube:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
/*-- next-prev --*/
.next-prev {
  text-align: center;
  font-size: 0;
  color: #979DA4;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: clamp(2rem, 4vw, 4rem);
}

.next-prev > * {
  margin-bottom: .5rem !important;
}

.next-prev::after::after {
  display: block;
  content: "";
  clear: both;
}

.next-prev a {
  color: #979DA4;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  border: 1px solid #c9ced2;
}

.next-prev a:hover {
  border-color: #D20F0F;
  background: #D20F0F;
  color: #fff;
}

.next-prev__inner {
  display: inline-block;
}

.next-prev .prev,
.next-prev .next {
  background: #c9ced2;
  color: #fff;
}

.page-numbers {
  padding: 0 0.75em;
  margin: 0 0.2rem;
  line-height: 1;
  transition: 0.2 0.3s ease 0s;
  font-size: 0.88rem;
  letter-spacing: 0;
  color: #fff;
  vertical-align: top;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: inline-block;
  line-height: 36px;
}

.page-numbers.current {
  border: 1px solid #D20F0F;
  color: #D20F0F;
}

.page-numbers.dots {
  color: #979DA4;
}

.nav-prev {
  float: left;
  margin-top: 24px;
  margin-top: 1.5rem;
}

.nav-next {
  float: right;
  margin-top: 24px;
  margin-top: 1.5rem;
}

/* print css
----------------------------------------------------------------*/
@media print {
  .header.fixed {
    display: none;
  }
}
