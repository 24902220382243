/*----------------------------------------------------------------
	common-parts
----------------------------------------------------------------*/

/*	heading
/--------------------------------------------------------------------*/
.cont-tit {
	@include cont-tit;
	&--center {
		@include cont-tit;
		text-align: center;
	}
}

.cont-tit2 {
	@include cont-tit2;
	&--center {
		@include cont-tit2;
		text-align: center;
	}
}

.heading-tit {
	text-align: center;
	line-height: 1.4;
	padding-bottom: calc(0.5rem + 0.3em);
	margin-bottom: calc(2rem + 0.3em);
	position: relative;
	@include f-w(400);
	@include f-s_xs(1.14, 6);
	@include media-breakpoint-up(xl) {
		@include f-size(24);
	}
	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		content: "";
		background-color: $main_c;
		width: 3rem;
		height: 1px;
	}
}

[class*="heading-tit2"] {
	text-align: center;
	line-height: 1.4;
	margin-bottom: calc(2rem + 0.3em);
	@include f-w(400);
	@include f-s_xs(1.14, 6);
	@include media-breakpoint-up(xl) {
		@include f-size(24);
	}
	& b {
		@include f-family(font02);
		@include f-w(400);
		display: block;
		@include f-s_xs(1.4, 10);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
	}
	& small {
		display: inline-block;
		position: relative;
		padding: 0 1.5em;
		@include f-em(11);
		&::before,
		&::after {
			position: absolute;
			width: 1em;
			height: 1px;
			content: "";
			top: calc(50% + 1px);
		}
		&::before {
			left: 0;
		}
		&::after {
			right: 0;
		}
	}
}

/* teble用のタイトル */
.table-tit {
	@extend .heading-tit;
}

/* ol
/--------------------------------------------------------------------*/
.common-ol {
	margin-left: 5px;
	counter-reset: ol-num;
	& > li {
		line-height: 1.4;
		padding-left: 1.5em;
		text-indent: -1.5em;
		position: relative;
		&::before {
			counter-increment: ol-num;
			content: counter(ol-num) ".";
			padding-right: 0.5em;
			text-align: center;
		}
		&:not(:last-child) {
			margin-bottom: 0.7rem;
		}
	}
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
	text-align: left;
	& li {
		line-height: 1.5;
		padding-left: 1.5rem;
		position: relative;
		&::before {
			position: absolute;
			content: "●";
			left: 0;
			top: 0.2em;
			color: $main_c;
			@include f-em(10);
		}
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
}
.common-ul2 {
	margin-left: 5px;
	text-align: left;
	& li {
		line-height: 1.5;
		padding-left: 1rem;
		position: relative;
		&::before {
			content: "・";
			position: absolute;
			color: $txt_c;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.3rem;
		}
	}
}

.caution-ul {
	margin-left: 5px;
	& li {
		line-height: 1.5;
		padding-left: 1.2rem;
		position: relative;
		color: $red;
		&::before {
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
	margin-left: 5px;
	margin-bottom: 2rem;
	& dt {
		line-height: 1.5;
		&:before {
			content: "●";
			padding-right: 0.3em;
		}
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}
.common-dl2 {
	margin-bottom: 1rem;
	& dt {
		line-height: 1.5;
		padding-bottom: 0.7rem;
		border-bottom: 1px solid $m-gray;
		margin-bottom: 0.7rem;
		@include f-em(18);
		@include f-w(500);
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

/* table
----------------------------------------------------------------*/
.common-table {
	width: 100%;
	@include f-em(15);
	& tr {
		border-bottom: 1px solid $b-gray;
		&:first-child {
			border-top: 1px solid $b-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		font-weight: normal;
		padding: 1rem 0;
		vertical-align: center;
		@include media-breakpoint-up(md) {
			display: table-cell;
			width: 25%;
			min-width: 180px;
			padding: 1.25rem 0;
		}
		@include media-breakpoint-up(xl) {
			width: 225px;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			width: 100%;
			padding-bottom: 0;
		}
	}
	& td {
		vertical-align: center;
		padding: 1rem 1.25rem 1rem 0.5rem;
		@include media-breakpoint-up(md) {
			display: table-cell;
			padding: 1rem 1.25rem 1rem 0.5rem;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			width: 100%;
			padding-top: 0;
			padding-left: 0;
		}
	}
}
.common-table2 {
	width: 100%;
	@include f-em(15);
	margin-bottom: 2rem;
	& tr {
		border-bottom: 2px solid $sub_c;
	}
	& th,
	& td.column-1 {
		text-align: left;
		font-weight: normal;
		padding: 1rem 5%;
		color: $white;
		background: $b-gray;
		@include media-breakpoint-up(lg) {
			padding: 1rem 1.5rem;
			width: 25%;
			min-width: 180px;
		}
		@include media-breakpoint-up(xl) {
			width: 225px;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	& td {
		padding: 1rem 5%;
		@include media-breakpoint-up(lg) {
			background-color: $white;
			padding: 1rem 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
}
/* table press CSS */
.tablepress td,
.tablepress th {
	padding: none;
}
.tablepress tbody td,
.tablepress tfoot th {
	border-top: none !important;
}

/* cotegory */
.category-list {
	line-height: 1.4;
	padding-bottom:2rem;
	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		& .category-list__items {
			margin-top: .5rem;
		}
	}
	&__tit {
		min-width: 110px;
		@include media-breakpoint-up(md) {
			padding-right: 1rem;
			position: relative;
			padding-top: 0.1rem;
			margin-right: 0.5rem;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 0.5rem;
		}
	}
	&__items {
		font-size: 0;
		& > * {
			display: inline-block;
			margin-right: 0.3rem;
			margin-bottom: 0.3rem;
			display: inline-block;
			@include f-em(16);
			@include media-breakpoint-up(lg) {
				margin-right: 0.5rem;
			}
		}
	}
	& [class*="category-item"] {
		@include f-size(13);
		padding: 0.2rem .7rem;
		min-width: 90px;
		vertical-align: top;
		margin-bottom: 0.4rem;
		@include media-breakpoint-up(lg) {
			min-width: 140px;
			padding: 0.3rem 1rem;
		}
	}
}

[class*="category-item"] {
	display: inline-block;
	background-color: $white;
	color: $gray;
	text-align: center;
	line-height: 1.4;
	padding: 0.3rem 1.25em;
	@include f-size(13);
	border-radius: 24px;
	@include l-sp(0.05em);
	@at-root a#{&} {
		@include dec-none;
		@include transition;
		&:hover {
			filter: saturate(200%);
		}
	}
}
.category-item {
	&.is-black {
		color: $white;
		background: $black;
		&:hover {
			background: $d-gray;
		}
	}
}

/* txt */
.txt {
	& p {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

/* align */
.align {
	&-right {
		float: right;
		margin: 0 0 0.7rem 0.7rem;
	}
	&-left {
		float: left;
		margin: 0.7rem 0.7rem 0 0;
	}
	&-center {
		display: block;
		margin: 0 auto 0.7rem;
	}
}

@import "../_plugins/lightbox"; //lightbox@import
