/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/

.loader {
	width: 100vw;
	height: 100vh;
	background: $sub_c;
	z-index: z-index(loader);
	position: fixed;
	transform-origin: right;
	&-block {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-circle {
		width: 0;
		height: 0;
		position: absolute;
		display: block;
		background: $main_c;
	}
	&-filter {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: z-index(loader-inner);
		transform-origin: right;
	}
}
/* sk-chase */
.sk-chase {
	width: 48px;
	height: 48px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
	&-wrap {
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.sk-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2s infinite ease-in-out both;
	&::before {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		background-color: $main_c;
		border-radius: 100%;
		animation: sk-chase-dot-before 2s infinite ease-in-out both;
	}
	@for $num from 1 through 6 {
		&:nth-child(#{$num}),
		&:nth-child(#{$num})::before {
			animation-delay:-1.2s + ($num / 10);
		}
	}
}

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}