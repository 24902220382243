/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
/*-- next-prev --*/
.next-prev {
	text-align: center;
	font-size: 0;
	color: $gray;
	@include f-w(500);
	@include flex-wrap;
	justify-content: center;
	margin-top: clamp(2rem,4vw,4rem);
	& > * {
		margin-bottom: .5rem !important;
	}
	&::after {
		@include clearfix;
	}
	a {
		color: $gray;
		text-decoration: none;
		@include transition;
		border: 1px solid $m-gray;
		&:hover {
			border-color: $main_c;
			background: $main_c;
			color: $white;
		}
	}
	&__inner {
		display: inline-block;
	}
	& .prev,
	& .next {
		background: $m-gray;
		color: $white;
	}
}

.page-numbers {
	padding: 0 0.75em;
	margin: 0 0.2rem;
	@include line-h(1);
	@include transition(0.2);
	@include f-size(14);
	@include l-sp(0);
	color: $white;
	vertical-align: top;
	min-width: 36px;
	min-height: 36px;
	border-radius: 50%;
	display: inline-block;
	line-height: 36px;
	&.current {
		border: 1px solid $main_c;
		color: $main_c;
	}
	&.dots {
		color: $gray;
	}
}

.nav-prev {
	float: left;
	margin-top: 24px;
	margin-top: 1.5rem;
}

.nav-next {
	float: right;
	margin-top: 24px;
	margin-top: 1.5rem;
}
