/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/

.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: z-index(header);
	width: 100%;
	padding-top: 0.6rem;
	padding-bottom: 0.8rem;
	background: $main_bg;
	height: $header_hight_sp_top;
	@include transition(all, 0.4, ease-in, 0);
	@include media-breakpoint-up(lg) {
		padding-top: 1.2rem;
		padding-bottom: 1.4rem;
		height: $header_hight_pc_top;
	}
	&.c-header {
		background-color: transparent;
	}
	&.fixed {
		background: rgba($main_bg, 0.9);
		@include media-breakpoint-up(lg) {
			padding-top: 0.5rem;
			padding-bottom: 0.7rem;
			height: $header_hight_pc_fixed;
		}
		@include media-breakpoint-down(md) {
			height: $header_hight_sp_fixed;
		}
	}
}

.h- {
	&inner {
		position: relative;
		height: 100%;
		@include m-a;
		padding: 0 5%;
		width: 100%;
		@include media-breakpoint-up(xl) {
			padding: 0 map-get($contmargin, pc);
		}
	}
	&flex {
		@include flex-between;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	&left {
		width: 70%;
		max-width: 300px;
		@include media-breakpoint-up(lg) {
			width: 30%;
			max-width: 320px;
		}
	}
	&logo {
		font-weight: normal;
		letter-spacing: 0;
		img {
			width: 100%;
		}
	}
	&right {
		@include flex-end;
		align-items: center;
	}
	&tel {
		margin-left: 2rem;
		@include f-family(font02);
		@include f-em(20);
		@include media-breakpoint-down(lg) {
			display: none;
		}
		i {
			@include f-em(13);
			color: $main_c;
			margin-right: 0.2em;
		}
		.header.c-header & {
			color: $white;
			i {
				color: $white;
			}
		}
		.header.fixed & {
			color: $txt_c;
			a {
				color: $txt_c;
			}
			i {
				color: $main_c;
			}
		}
	}
}

// link position
[id*="link-"],
.scroll-position {
	padding-top: $header_hight_sp_fixed;
	margin-top: -#{$header_hight_sp_fixed};
	@include media-breakpoint-up(xl) {
		padding-top: $header_hight_pc_fixed;
		margin-top: -#{$header_hight_pc_fixed};
	}
}
